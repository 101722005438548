import axios from "axios";

export const fetchBlogs = async () => {
  try {
    const data = await axios.get(
      "https://fearless-novelty-ed7c58dabc.strapiapp.com/api/blogs?populate=*");
    if (data.data.data.length === 0) {
      return { status: 500 };
    } else return data.data.data;
  } catch (error) {
    const status = error.response ? error.response.status : 500;
    return { status };
  }
};
