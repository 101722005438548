import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Helmet } from "react-helmet";
import { fetchBlogs } from "../api";
import "./css/blogsDetail.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const BlogsDetail = () => {
  const { Title } = useParams();
  const [data, setData] = useState([]);
  const [blog, setBlog] = useState(null);

  useEffect(()=>{
    window.scroll(0,0)
  })

  useEffect(() => {
    async function loadBlogs() {
      const blogsData = await fetchBlogs();
      setData(blogsData);
    }
    loadBlogs();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const matchedBlog = data.find((b) => b.Title === Title);
      setBlog(matchedBlog);
    }
  }, [data, Title]);

  if (!blog) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }
  return (
    <>
      <Helmet>
        <title>{blog.MetaTitle}</title>
        <meta name="description" content={blog.MetaDescription} />
      </Helmet>
      <Header />
      <div className="blog-detail-container">
        <div className="blog-sidebar">
          <h2 className="sidebar-title">{blog.Title}</h2>
          <div className="blog-meta-d">
            <span className="author">{blog.Author}</span>
            <span className="date">{blog.Date}</span>
          </div>
        </div>

        <div className="blog-main">
          {/* Featured Image */}
          <div className="featured-image-container">
            <img
              src={blog.CardImage.url}
              alt={blog.Title}
              className="featured-image"
            />
          </div>

          {/* Blog Content */}
          <div className="blog-content">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {blog.Blog}
            </ReactMarkdown>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogsDetail;
