import * as React from "react";
import { useEffect, useState } from "react";
import "./css/blogsLanding.css";
import { Link } from "react-router-dom";
import { fetchBlogs } from "../api";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const BlogsLanding = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const blogsPerPage = 5; // Number of blogs per page

  useEffect(() => {
    window.scroll(0, 0);
  });

  async function fetchBlogsData() {
    const blogsData = await fetchBlogs();
    setData(blogsData);
  }

  useEffect(() => {
    fetchBlogsData();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Calculate the blogs to be displayed based on the current page

  if (data.length === 0) {
    return (
      <>
        <Header />
        <div className="blogs-landing">
          <div className="banner">
            <img
              src="https://cdn.pixabay.com/photo/2021/10/11/17/54/technology-6701504_1280.jpg"
              alt="Blog Banner"
              className="banner-image"
            />
            <div className="banner-overlay">
              <h1 className="banner-title">Welcome to Our Blogs</h1>
            </div>
          </div>
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
        <Footer />
      </>
    );
  }

  if (data.status === 500) {
    return (
      <>
        <Header />
        <div className="blogs-landing">
          <div className="banner">
            <img
              src="https://cdn.pixabay.com/photo/2021/10/11/17/54/technology-6701504_1280.jpg"
              alt="Blog Banner"
              className="banner-image"
            />
            <div className="banner-overlay">
              <h1 className="banner-title">Welcome to Our Blogs</h1>
            </div>
          </div>
          <h1 style={{ width: "100%", textAlign: "center" }}>Coming Soon!</h1>
        </div>
        <Footer />
      </>
    );
  }
  const startIndex = (page - 1) * blogsPerPage;
  const currentBlogs = data.slice(startIndex, startIndex + blogsPerPage);
  return (
    <>
      <Header />
      <div className="blogs-landing">
        <div className="banner">
          <img
            src="https://cdn.pixabay.com/photo/2021/10/11/17/54/technology-6701504_1280.jpg"
            alt="Blog Banner"
            className="banner-image"
          />
          <div className="banner-overlay">
            <h1 className="banner-title">Welcome to Our Blogs</h1>
          </div>
        </div>

        {/* Blog Cards */}
        <div className="blogs-list">
          {currentBlogs.map((blog) => (
            <div className="blog-card" key={blog.id}>
              <Link to={`/blogs/${blog.Title}`}>
                <h2 className="blog-title">{blog.Title}</h2>
                <p className="blog-excerpt">{blog.Excerpt}</p>
                <div className="blog-meta">
                  <span>{blog.Date}</span> | <span>{blog.Author}</span>
                </div>
                <img src={blog.CardImage.url} alt="cardimg" />
              </Link>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <Stack spacing={2} alignItems="center" className="pagination">
          <Pagination
            count={Math.ceil(data.length / blogsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      </div>
      <Footer />
    </>
  );
};

export default BlogsLanding;
